.gridFromToTextBox {
  width: 100%;
  display: grid;
  grid-template-columns: 40px 1fr 65px;
  grid-template-rows: 1fr 1fr;

  height: 100px;
}

.greenToRed {
  grid-row: 1/-1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.textBoxes {
  grid-row: 1/-1;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 2.2rem;
}

.arrrowUpAndDown {
  /* grid-row: 1/-1; */

  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1rem;
}

.dotGreen {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #649300;
}

.hrLine {
  background-color: #dedede;
  border: none;
  height: 1px;
  width: 45px;

  transform: rotate(90deg);
}

.dotRed {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #ff0000;
}

.textBoxes input {
  height: 43px;
  border: none;
  outline: none;
  background-color: #e9e9e9;
  border-radius: 5px;

  color: #3a3c49;

  padding: 0 1rem;
  font-size: 1.6rem;
  font-weight: 600;
  width: 110%;
}

.textBoxes input::placeholder {
  color: #000;
}

.textBoxes input:focus {
  color: #000;
}

.background {
  background-color: #4b4b4b;

  width: 38px;
  height: 38px;
  border-radius: 150px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.arrrowUpAndDownImage {
  padding: 1rem;
}

.disabledButClickableInput {
  opacity: 0.9;
  cursor: pointer;
}
