.paidAcknowledgementCheckbox {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ///////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// */
/* The checkbox styles */

/* The label container */
.labelContainer {
  display: block;
  position: relative;
  padding-left: 3.6rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
  font-size: 1.6rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.labelContainer span {
  margin-top: -0.5rem;
}

/* Hide the browser's default checkbox */
.labelContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.4rem;
  width: 2.4rem;
  background-color: #eee;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.labelContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.labelContainer input:checked ~ .checkmark {
  background-color: #eb984e;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.labelContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.labelContainer .checkmark:after {
  left: 0.9rem;
  top: 0.4rem;
  width: 0.4rem;
  height: 1rem;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
