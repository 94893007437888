.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: min(425px, 100vw);
}

.splashScreenTaxiwarsLogo {
  width: 55%;
}

.splashScreenLandscape {
  width: min(425px, 100vw);
  position: absolute;
  bottom: 0;
}
