.makePayment {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
}

.nominalFeeButton {
  display: flex;

  /* align-items: center; */
  /* justify-content: space-between; */
  width: 94%;
  background-color: #dcdcdc;

  border-radius: 3px;
}
.infoBar {
  display: flex;
  width: 150%;

  flex-direction: column;

  color: #000;
  align-items: flex-start;
  justify-content: center;

  padding-left: 1rem;
}
.infoBar span:nth-child(1) {
  font-size: 1.6rem;
  font-weight: 700;
}

.infoBar span:nth-child(2) {
  font-size: 1.4rem;
  font-weight: 500;
  color: #848484;
}
