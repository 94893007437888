.backPageHeadingWrapper {
  height: 100%;
  width: min(100vw, 425px);

  /* / */
  /* max-width: 425px; */
  /* width: clamp(100vw, 100%, 425px); */
  background-color: var(--main-bg-color-black);

  position: relative;

  /* display: flex;
  flex-direction: column; */
}

.appbar {
  width: 100%;
  top: 0;
  /* No absolute position required for our use case */
  /* position: absolute; */
  z-index: 1;
  background-color: #383838;
  padding: 0 1rem;
  margin-bottom: 0.2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  /* position: absolute; */
  flex: 1;
  height: 92%;
  overflow-y: auto;
  background-color: var(--main-bg-color-black);
  padding-bottom: 2rem;
}

/* ********************* */
/* Appbar */

.arrowBackWhite {
  height: 14px;
  width: 15px;
}

.logoHeadingText {
  font-size: 1.6rem;
  font-weight: 400;
}
