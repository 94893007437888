.postRideFrom {
  display: flex;
  flex-direction: column;

  /* align-items: center; */

  padding-top: 1rem;
  gap: 2rem;
}

.timeSelectionAlignment {
  align-self: flex-start;
  margin-left: 1.7rem;
}

.list {
  height: calc(100vh - 420px);
  overflow-y: auto;
}
