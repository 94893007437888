.savedPlaces {
  width: 82%;
  /* padding: 1rem 0; */
  margin: 0 auto;
}

.row {
  display: flex;
  align-items: center;
}

.starImage {
  height: 16px;
  width: 16px;
  margin: 0.3rem 1rem 1rem 0;
}

.textLine {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 1rem 0;
}

.hr {
  margin-left: 2.6rem;

  border: 1px solid #494949;
}
