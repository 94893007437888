.sosButton {
  z-index: 5;
  position: absolute;
  bottom: 5rem;

  font-size: 1.6rem;
  background-color: aliceblue;
}
.relativeBox {
  position: relative;
  background-color: aquamarine;

  /* height: 5px;
  width: 5px; */
}
.left {
  left: 3rem;
}

.right {
  right: 5rem;
}

.floatingActionButton {
  /* z-index: 2; */
  position: absolute;
  /* Default */
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  /* Custom */
  background-color: red;
  padding: 1.4rem;
  outline: none;
  border-radius: 100%;
  /* min-width: 130px; */
  color: #fff;
  font-weight: bold;
  -webkit-tap-highlight-color: transparent;
  /* transition: background-color 0.3s ease-in; */
  user-select: none;

  aspect-ratio: 1/1;

  /* transition: all 0.3s cubic-bezier(1, 0, 0, 1); */
  /* transition: background-color 0.3s cubic-bezier(0.02, 0.86, 0.98, 0.15); */

  transform: translate(-100%, -100%);
}

.floatingActionButton:focus {
  outline: none;
}

.floatingActionButton:active {
  /* background-color: rgba(255, 0, 0, 0.585); */
}

.sosBox {
  /* z-index: 5; */
  position: absolute;
  background-color: rgba(171, 180, 4, 0.642);
  transform: translate(-100%, -100%);

  width: min(25rem, 100vw);
  /* aspect-ratio: 11/12; */

  /*  */
  border-radius: 15px 15px 15px 15px;
  border: 4px solid red;
  /* padding: 1rem; */
  color: black;
  background-color: #e9e9e9;

  display: flex;

  flex-direction: column;
  gap: 1rem;
  /* align-items: center; */
  /* justify-content: center; */
}

.hazardSign {
  font-size: 8rem;
  color: red;
  text-align: center;
  /* background-color: aqua; */
  margin-bottom: -1.4rem;
}
.onlyForEmergencies {
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  color: red;
  text-align: center;
}

.headingPara {
  font-size: 1.2rem;
  color: rgb(130, 130, 130);
  text-align: center;
  padding: 0 0.2rem;
}

.olList {
  font-size: 1.4rem;
  padding-left: 5rem;
  color: rgb(63, 63, 63);
}

.numberPresentText {
  font-size: 1.4rem;
  text-align: center;
  padding: 0 1rem;
}
.buttonsRow {
  display: flex;
  /* justify-content: space-; */

  border-radius: 0px 0px 11px 11px;
  width: 100%;

  /* background-color: rgb(195, 164, 223); */
}

/* .divider {
  height: 33px;
  width: 2px;
  background-color: red;
} */
.topButton {
  padding-top: 0.9rem;
  padding-bottom: 0.6rem;
  /* color: white; */
  /* background-color: red; */
  width: 50%;
  text-align: center;
  /* border-top: 2px solid red; */
  border-top: 2px solid red;
}

.bottomButton {
  text-align: center;
  padding-top: 0.9rem;
  padding-bottom: 0.6rem;
  width: 50%;
  border-top: 2px solid red;
  border-left: 2px solid red;
}

.button {
}

.headingInfo {
  font-size: 1.4rem;
  text-align: center;
  margin-top: 1rem;
}

.numberInputBoxCombined {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  border: 2px solid #5e5e5e;
  border-radius: 2px;
}

.inputBoxCommon {
  /* background-color: #e9e9e9; */
  /* border-radius: 3px; */
  /* height: 49px; */
  border: none;
  padding: 0.2rem;
}

.inputBoxCommon:focus {
  outline: none;
}
.countryCodeInputBox {
  width: 3rem;

  /* background-color: #e9e9e9; */
  /* border-radius: 3px; */
  /* height: 49px; */
  border: none;
  border-right: 1px solid #5e5e5e;
}

.numberInputBox {
  width: 11rem;
}

.onlySendTaxiwars {
  font-size: 1.2rem;
  border: 2px solid red;
  width: max-content;
  padding: 0.4rem;
  border-radius: 5px;
  margin: auto;
}

.errorText {
  margin: -0.4rem auto;
}
