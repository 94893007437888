.children {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  margin-bottom: -5rem;
}

.termsCondistionRefundPolicy {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 1rem;
}

.refundPolicy {
  font-size: 1.4rem;
  /* font-weight: 400; */
  color: #848484;

  text-align: center;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 100%; */
}
