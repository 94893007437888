.scrollable {
  height: 90vh;
  overflow-y: auto;
  padding-right: 10px;
  width: 100%;
  padding: 0 2rem;
}

.termsNCondition {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  justify-content: center;
  padding-top: 2rem;
}

.termsNCondition h2 {
  font-size: 2rem;
  margin-left: -2rem;
}

.termsNCondition p {
  font-size: 1.4rem;
  text-align: justify;
}

.termsNCondition h3 {
  font-size: 1.8rem;
  margin-left: -1rem;
}
