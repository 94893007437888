.inputBoxLargeMessage {
  background-color: #e9e9e9;
  border-radius: 3px;
  height: 160px;
  border: none;

  font-size: 1.6rem;
  font-weight: 600;
  padding: 1rem;
  width: 94%;
  resize: none;
}

.inputBoxLargeMessage:focus {
  outline: none;
}

.inputBoxLargeMessage::placeholder {
  font-weight: 400;
  font-size: 1.4rem;
  color: #848484;
}
