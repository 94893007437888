.floatingAction {
  z-index: 5;
  position: absolute;
  bottom: calc(4rem + 75px);

  font-size: 1.8rem;
}

.left {
  left: 3rem;
}

.right {
  right: 3rem;
}

.floatingActionButton {
  /* Default */
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  /* Custom */
  /* background-color: #419c00; */
  padding: 1.6rem;
  outline: none;
  border-radius: 30px;
  min-width: 130px;
  color: #fff;
  font-weight: bold;
  -webkit-tap-highlight-color: transparent;
  /* transition: background-color 0.3s ease-in; */
  user-select: none;
}

.floatingActionButton:focus {
  outline: none;
}

.floatingActionButton:active {
  background-color: #347c00;
}

.greenColorBg {
  background-color: #419c00;
  border-radius: 30px;
}
.flashing {
  animation: flashColor 0.5s infinite;
  transition: background-color 0.5s ease-in-out;
  border-radius: 30px;
}

@keyframes flashColor {
  0% {
    background-color: #347c00;
  }
  50% {
    background-color: rgb(167, 22, 22);
  }
  100% {
    background-color: #347c00;
  }
}
