.inputLabelUserProfileImage {
  height: 124px;
  width: 124px;
  margin-top: 2rem;
}

.inputLabel {
  position: relative;
  clip: auto;
}

.userGeneric {
  position: absolute;
  /* filter: invert(); */

  height: 118px;
  width: 118px;

  object-fit: cover;

  border-radius: 50%;
}

.cameraDiv {
  position: absolute;
  right: -114px;
  bottom: -129px;
  /* right: 5px;
  bottom: 15px; */
  background-color: #d9d9d9;
  /* height: 43;
  width: 43; */
  padding: 1rem;
  border-radius: 150px;

  display: flex;
  align-items: center;
  justify-content: center;
}
