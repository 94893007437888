.googleMapComponent {
  width: 94%;
  aspect-ratio: 1/1;
  background-color: #383838;

  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;

  position: relative;
}

.mapMarkerRed {
  position: absolute;
  top: 50%;
  left: 50%;
  /* calculated for the bottom point to be at the center */
  transform: translate(-50%, -90%);

  pointer-events: none;
}
