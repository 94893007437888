.rideCompleted {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* align-content: stretch; */
  gap: 2rem;
  width: 94%;
  margin: 0 auto;
}
.input {
  align-self: center;
  height: 3rem;
  width: 80%;
  border-radius: 0.5rem;
  padding: 0 0.4rem;
}
