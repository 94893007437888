.noEditGridFromToCancelSupportBox {
  width: 94%;
  border: 1px solid #dcdcdc;
  border-radius: 3px;

  display: flex;
  flex-direction: column;

  background-color: #222222;
  padding: 0.4rem 0 0 0;
}

.gridSystem {
  width: 100%;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: 1fr 1fr;

  height: 100px;
}

.greenToRed {
  grid-row: 1/-1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.textBoxes {
  grid-row: 1/-1;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 1.9rem;
}

.dotGreen {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #649300;
}

.hrLine {
  background-color: #dedede;
  border: none;
  height: 1px;
  width: 45px;

  transform: rotate(90deg);
}

.dotRed {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #ff0000;
}

.textBoxes p {
  background-color: transparent;
  border-radius: 5px;
  color: #848484;
  font-size: 1.4rem;
  font-weight: 500;
}

.horizontalLine {
  border: none;
  height: 1px;
  background-color: #dedede;
  width: 90%;
}

.fullhorizontalLine {
  border: none;
  height: 1px;
  background-color: #dedede;

  width: 100%;
}

.cancelSupport {
  display: flex;

  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.cancelSupport button {
  background-color: transparent;
  border: none;
  outline: none;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 1.3rem 0;
}

.verticleLine {
  border: none;
  background-color: #fff;
  width: 12px;
  height: 1px;
  transform: rotate(90deg);
}
