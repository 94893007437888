.container {
  height: 100vh;
  width: min(425px, 100vw);
  /* background-color: ; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.4rem;
  padding-bottom: 1.2rem;
}

.logoWithTextRow {
  width: clamp(235px, 60%, 425px);
  /* margin: 3rem 0 3rem 0; */
}

.welcomeScreenTwoTaxi {
  /* max-height: 45%; */
  width: min(425px, 100%);
  /* background-color: aliceblue; */
  object-fit: cover;
  /* object-position: 0 5rem; */
}

.headingQuestion {
  font-size: 2.2rem;
  font-weight: 700;
  /* color: #fff; */
  text-align: center;
}

.paraAnswer {
  width: 94%;
  font-size: 1.4rem;
  /* color: #fff; */
  text-align: center;
}

.termsCondistionRefundPolicy {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 1rem;
}

.termsNCondition {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 100%; */
}

.refundPolicy {
  font-size: 1.4rem;
  /* font-weight: 400; */
  color: #848484;

  text-align: center;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 100%; */
}
