.inputOptionBoxForCityDriver {
  background-color: #e9e9e9;
  border-radius: 3px;
  height: 49px;
  border: none;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0 1rem;
  width: 94%;
  color: #000;
}

.inputOptionBoxForCityDriver:focus {
  outline: none;
  color: #000;
}

.inputOptionBoxForCityDriver::placeholder {
  font-weight: 400;
  font-size: 1.4rem;
  color: #000;
}

select.inputOptionBoxForCityDriver {
  background-color: #e9e9e9;
  border-radius: 3px;
  height: 49px;
  border: none;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0 1rem;
  width: 94%;
  color: #000;
}

select.inputOptionBoxForCityDriver:focus {
  outline: none;
  color: #000;
}

select.inputOptionBoxForCityDriver::placeholder {
  font-weight: 400;
  font-size: 1.4rem;
  color: #000;
}
