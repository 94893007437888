.riderPastRideDetails {
  padding-top: 1.4rem;
  display: flex;
  flex-direction: column;
  /* Align items will shrink the middle portion */
  /* align-items: center; */
  /* justify-content: center; */
  gap: 2rem;
  width: 94%;
  margin: 0 auto;
}
