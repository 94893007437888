.labelWithInput {
  width: 100%;
}

.inputLabel {
  font-size: 1.4rem;

  font-weight: 700;
  text-align: left;
  width: 94%;
  margin: 0 auto;
  margin-bottom: 0.4rem;
}

.fileUploadName {
  display: flex;
  align-items: center;
  background-color: #e9e9e9;
  border-radius: 3px;
  width: 94%;
  margin: 0 auto;

  height: 49px;
  color: #848484;
  justify-content: space-between;
}

.labelName {
  margin-left: 1rem;
  font-size: 1.4rem;
}

.camera {
  height: 22px;
  width: 25px;
  margin-right: 1rem;
}
