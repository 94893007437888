/* Custom Fonts */
@font-face {
  font-family: "Proxima Nova Rg";
  src: url("./assets/fonts/ProximaNovaFont.otf") format("opentype");
}
/* @font-face {
  font-family: "Gotham Black";
  src: url("./assets/fonts/GothamBlackRegular.ttf") format("truetype");
} */

:root {
  --main-bg-color-black: #000;
  --main-text-color-white: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: none;
  scrollbar-color: #000 #383838;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0;
}

*::-webkit-scrollbar-track {
  background: #000;
}

*::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 0px;
  border: 0px none transparent;
}

html {
  font-size: 62.5%;
  height: 100vh;
  /* max-width: 425px; */
}

body {
  height: 100%;
  font-family: "Proxima Nova Rg", sans-serif;
  color: var(--main-text-color-white);
  /* For testing only */
  /* background-color: rgb(62, 62, 62); */
  background-color: #000;

  width: 100%;

  display: flex;
  justify-content: center;
  align-content: center;

  /* overflow: hidden; */

  /* Disable tesxt selection */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
