.button {
  /* equivalent to leaving round about 14px space */
  width: 94%;
  /* height: 49px; */
  background-color: #fc8018;
  margin: 0 auto;
  border-radius: 3px;
  padding: 1.5rem 0;

  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
}
