.makePaymentCcAvenueWaitingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 2rem; */
  width: 100%;
  font-size: 1.6rem;
}

.heading {
  text-align: center;
  font-size: 2rem;
}

.redirect {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.redirect p {
  text-align: center;
}
