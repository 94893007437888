.selectTimeDivLine {
  display: flex;
  gap: 1rem;
  font-size: 1.4rem;
}

.selectTimeDiv {
  font-size: 1.4rem;
  color: #515151;
  font-weight: 700;
  background-color: #d1d1d1;
  /* height: 30px; */
  /* width: 75px; */

  padding: 1rem;

  border-radius: 25px;

  display: flex;
  gap: 1rem;
}

.resetButton {
  background-color: gray;
  padding: 1rem;
  border-radius: 25px;
}

.paidAcknowledgementCheckbox {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ///////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// */
/* The checkbox styles */

/* The label container */
.labelContainer {
  display: block;
  position: relative;
  padding-left: 2.8rem;
  /* margin-bottom: 1rem; */
  cursor: pointer;
  font-size: 1.6rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.labelContainer span {
  margin-top: -0.5rem;
}

/* Hide the browser's default checkbox */
.labelContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.4rem;
  width: 2.4rem;
  background-color: #eee;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.labelContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.labelContainer input:checked ~ .checkmark {
  background-color: #419c00;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.labelContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.labelContainer .checkmark:after {
  left: 0.9rem;
  top: 0.4rem;
  width: 0.4rem;
  height: 1rem;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.logsBottomModal {
  position: absolute;
  z-index: 10;

  height: 90vh;
  width: min(100vw, 425px);
}

.modalBackdrop {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1px);

  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #fc7f1860; */
}
.visibleModal {
  background-color: #393939;
  width: max(300px, 75%);
  padding: 1rem;
  border-radius: 5px;

  /* border: 2px solid rgba(255, 166, 0, 0.093); */

  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.modalMainHeading {
  margin-top: 1rem;
  font-size: 1.8rem;
  font-weight: 400;
  text-align: center;
}

.modalSubHeading {
  font-size: 1.4rem;
  color: rgb(169, 169, 169);
  text-align: center;
  /* margin-bottom: 1rem; */
}

.para {
  margin-top: -1rem;
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: -1rem;
}

.email {
  font-size: 1.4rem;
  margin-top: -1rem;
  text-align: center;
  color: #9f9f9f;
}

.buttonRow {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 2rem;
}

.button {
  /* Resetting default styles */
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  /* line-height: normal; */
  /* text-align: inherit; */
  /* text-decoration: none; */
  cursor: pointer;
  /* overflow: visible; */
  outline: none;

  font-size: 1.6rem;
  padding: 0.6rem 1rem;
  background-color: #fc8018;
  border-radius: 5px;

  min-width: 96px;
}

.ulList {
  font-size: 1.4rem;
  color: rgb(169, 169, 169);
  list-style: none;

  padding-left: 1rem;
}
