.postRideFromToMapSelection {
  display: flex;
  flex-direction: column;

  align-items: center;

  padding-top: 1rem;
  gap: 1rem;

  height: 90%;
  /* background-color: red; */
}

.timeSelectionAlignment {
  align-self: flex-start;
  margin-left: 1.7rem;
}

.distanceDurationRow {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 94%;
  font-size: 1.4rem;
}

.bottomMessagePara {
  margin: 0 auto;
  /* width: 94%; */
  /* text-align: center; */
  /* align-items: center; */

  font-size: 1.4rem;
  /* font-weight: 400; */
  color: #848484;

  text-align: center;

  width: 90%;
}
.logsBottomModal {
  position: absolute;
  z-index: 10;

  height: 90vh;
  width: min(100vw, 425px);
}

.modalBackdrop {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1px);

  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #fc7f1860; */
}
.visibleModal {
  background-color: #393939;
  width: max(300px, 75%);
  padding: 1rem;
  border-radius: 5px;

  /* border: 2px solid rgba(255, 166, 0, 0.093); */

  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.modalMainHeading {
  margin-top: 1rem;
  font-size: 1.8rem;
  font-weight: 400;
  text-align: center;
}

.modalSubHeading {
  font-size: 1.4rem;
  color: rgb(169, 169, 169);
  text-align: center;
  /* margin-bottom: 1rem; */
}
.fareWithVehicle {
  display: flex;
  justify-content: space-between;
  gap: 0.2rem;
}
.buttonRow {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 2rem;
}

.button {
  /* Resetting default styles */
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  font-weight: bold;
  color: inherit;
  /* line-height: normal; */
  /* text-align: inherit; */
  /* text-decoration: none; */
  cursor: pointer;
  /* overflow: visible; */
  outline: none;

  font-size: 1.6rem;
  padding: 0.6rem 1rem;
  background-color: #fc8018;
  border-radius: 5px;

  min-width: 96px;
}
