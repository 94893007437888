.riderPastRides {
  padding-top: 1.4rem;
  display: flex;
  flex-direction: column;
  /* Align items will shrink the middle portion */
  /* align-items: center; */
  justify-content: center;
  gap: 2rem;
  width: 94%;
  margin: 0 auto;
}

.background {
  background-color: #3a3a3a;

  display: flex;
  flex-direction: column;
  gap: 3px;
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
}

.button {
  background-color: grey;
  border: none;
  margin: 1rem;
  font-weight: 700;
  font-size: 2rem;
  padding: 1rem;
  border-radius: 0.5em;
}

.active {
  background-color: orange;
}
