.scheduledRide {
  display: flex;
  flex-direction: column;

  /* align-items: center; */
  width: min(425px, 100vw);

  padding-top: 1rem;
  gap: 2rem;
}

.noRides {
  font-size: 2rem;
  text-align: center;
}
