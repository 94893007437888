.pleasePayAmountParaLine {
  font-size: 1.6rem;
  text-align: center;
}

.pleasePayAmountParaLine span {
  font-weight: bold;
}

.tollAmountLine {
  font-size: 1.5rem;
  text-align: center;
  color: rgb(230, 0, 0);
  /* color: rgb(224, 224, 0); */
}
