.staticMapWithTimeCancelledCard {
  display: flex;
  flex-direction: column;
}

.heading {
  background-color: #323232;
  font-size: 1.4rem;

  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: 0.4rem;
  padding: 0.6rem 0;
}

.cancelled {
  color: #ff0000;
  font-weight: 700;
}

.staticMapImage {
  height: 201px;
  /* aspect-ratio: 3/2; */

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #212121;
  padding: 1rem 0.6rem;
}
