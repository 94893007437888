.rideCarDriverPickupDropDetailsCard {
}

.hrLine {
  border: none;
  height: 1px;
  background-color: #4f4f4f;
}

.carImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cab {
  height: 35px;
  width: 65px;
  margin: auto 0;

  object-fit: cover;

  /* position: absolute;
  width: 138px;
  height: 80px;
  left: -138px;
  object-fit: cover;
  top: 16px;
  padding-right: 5px; */
}

.carTypeAndName {
  margin: auto 0;
}
.carTypeAndName p:nth-child(1) {
  font-size: 1.4rem;
}

.carTypeAndName p:nth-child(2) {
  font-size: 1.2rem;
  color: #b6b6b6;
}

.driverPic {
  margin: auto;
}

.userGeneric {
  height: 40px;
  width: 40px;
  /* margin: 0 1rem; */

  /* filter: invert(); */
}

.driverName {
  font-size: 1.4rem;
  font-weight: 700;

  margin: auto 0;
}

.time {
  font-size: 1.2rem;
  color: #b6b6b6;
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.originDestination {
  font-size: 1.2rem;
  color: #b6b6b6;

  margin: auto 0;

  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.dot {
  display: inline-block;
  height: 10px;
  width: 10px;

  border-radius: 50%;
  margin-right: 1rem;
}
.green {
  background-color: #649300;
}
.red {
  background-color: #ff0000;
}

.price {
  margin: auto;
}

.paperMoney {
  height: 17.5px;
}

.amountSpent {
  margin: auto 0;

  font-size: 1.4rem;
  font-weight: 700;
}
