.rideCompleted {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* align-content: stretch; */
  gap: 2rem;
  width: 94%;
  margin: 0 auto;
}

.heading {
  background-color: #323232;
  font-size: 1.4rem;

  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: 0.4rem;
  padding: 0.6rem 0;
}

.individualRating {
  /* height: 201px; */
  /* aspect-ratio: 3/2; */

  display: flex;
  align-items: center;
  /* justify-content: center; */

  background-color: #212121;
  padding: 1rem 0.6rem;

  font-size: 1.6rem;
}

.individualRating img {
  margin: 0 1rem;
  height: 16px;
  filter: grayscale();
}

.individualRating img.active {
  filter: none;
}
.input {
  align-self: center;
  height: 3rem;
  width: 80%;
  border-radius: 0.5rem;
  padding: 0 0.4rem;
}
