.container {
  height: 100%;
  width: min(425px, 100vw);

  /* position: relative; */
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.logoWithTextRow {
  width: clamp(235px, 60%, 425px);
  margin: 3rem 0 3rem 0;
}

.riderDriverSignBottomPicture {
  /* position: absolute; */
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0; */
  width: min(425px, 100%);
  filter: brightness(49%);
}
