.googleMapArrivingSoonOtpDriverLocation {
  width: 100%;
  /* aspect-ratio: 5/5; */
  /* Caluclated for the polyline map to occupy the extra space */
  height: calc(100vh - 340px);
  background-color: #383838;
  /* border: 1px solid salmon; */

  /* margin-bottom: 1rem; */
  /* padding-bottom: 1rem; */
  /* border-radius: 5px; */

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;
}

.loadingArea {
  display: flex;
  flex-direction: column;

  font-size: 1.6rem;
  /* background-color: rgba(240, 248, 255, 0.215); */
}
