.whereToSearch {
  height: 43px;
  width: 94%;

  background-color: #e9e9e9;

  display: flex;

  align-items: center;
  /* justify-content: space-between; */

  border-radius: 25px;

  margin: 0 auto;
}

.searchIcon {
  height: 14px;
  width: 14px;

  margin-left: 1.7rem;
}

.inputBar {
  flex-grow: 1;
  align-self: stretch;

  background-color: #e9e9e9;

  border: none;

  outline: none;

  padding: 1rem;
  font-size: 1.6rem;
  font-weight: 700;

  border-radius: 25px;
  /* background-color: red; */
}

.clock {
  height: 11px;
  width: 11px;

  filter: invert();
}

.inputBar:focus {
  background-color: #e9e9e9;
}
