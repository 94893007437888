.visitedHistoryList {
  width: 82%;
  /* margin: 1rem 0; */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  margin: 0 auto;
}

.row {
  display: flex;
  align-items: center;
}

.starImage {
  height: 16px;
  width: 16px;
  margin: 0.3rem 1rem 1rem 0;
}
.mapThin {
  height: 20px;
  width: 20px;
  margin: 0.3rem 1rem 1rem 0;
  filter: invert(66%) sepia(0%) saturate(1256%) hue-rotate(183deg)
    brightness(90%) contrast(81%);
}
.textPara {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.textLine {
  font-size: 1.4rem;
  font-weight: 700;
  /* margin: 1rem 0; */
}

.subTextLine {
  font-size: 1.4rem;
  /* font-weight: 400; */
  color: #848484;
  margin-top: 0.4rem;
}

.hr {
  margin-left: 2.6rem;

  border: 1px solid #494949;
}
