.selectBidContinue {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* height: 100%; */
}

.bidHeadingAndSelect {
  width: 94%;
  /* align-self: stretch; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem;
}

.heading {
  font-size: 1.8rem;
  font-weight: 700;
  color: #fc8018;
  text-align: center;
  width: 100%;
}

.heading span {
  font-weight: 400;
}

.bidCardDiv {
  width: 100%;
  height: calc(100vh - 400px);
  /* aspect-ratio: 1/1; */
  /* flex-grow: 1; */
  /* set the flex-grow property to 1 */
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  /* margin-bottom: 1rem; */
}
/* 
.selectBidContinue:nth-child(3) {
  margin-top: auto;
} */

.bottomButton {
  align-self: stretch;
  margin-top: 1rem;
}

/* .selectDiv {
  margin: 0.2rem;
} */

.sortDivBase {
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-align: center; */

  gap: 1px;

  background-color: #ffffff;

  padding: 1px;

  border-radius: 30px;
  font-size: 1.6rem;

  /* font-size: 1.6rem; */
}
.filterButton {
  /* Resetting default styles */
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  /* line-height: normal; */
  /* text-align: inherit; */
  /* text-decoration: none; */
  cursor: pointer;
  /* overflow: visible; */
  /* outline: none; */

  padding: 0.4rem;
  background-color: #000;

  transition: background-color 0.3s ease-out;
}

.selectedFilter {
  background-color: #fc8018;
}

.leftButton {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding: 0.4rem 1rem;
  margin: 0;
}

.rightButton {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 0.4rem 1rem;
}

.sortText {
  font-size: 1.4rem;
  font-weight: 500;
}

.arrowSortUpDown {
  height: 11px;

  width: 15px;
}
