.bookRideWith {
  height: 73px;
  width: 94%;

  background-color: #383838;

  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0.5rem;
  gap: 2rem;
  margin: 0 auto;
}

.backButton {
  padding: 1rem;
  background-color: #4b4b4b;
  border-radius: 50%;
}

.cabImage {
  height: 42px;
}

.bookRideText {
  font-size: 1.8rem;

  font-weight: 700;
}

.bookRideTextGreen {
  color: #419c00;
}
