.inputBox {
  background-color: #e9e9e9;
  border-radius: 3px;
  height: 49px;
  border: none;

  font-size: 1.6rem;
  font-weight: 600;
  padding: 0 1rem;
  width: 94%;
}

.inputBox:focus {
  outline: none;
}

.inputBox::placeholder {
  font-weight: 400;
  font-size: 1.4rem;
  color: #848484;
}
