.riderNamePickupDropCancelCard {
  width: 94%;

  background-color: #e9e9e9;
  border-radius: 3px;
}

.upperPart {
  padding-top: 0.4rem;
  display: flex;
  /* align-self: stretch; */
  justify-content: space-between;
}

.writtenContent {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1rem 0 1rem 1rem;
}

.riderNameArrivingTime p:nth-child(1) {
  font-size: 2.4rem;
  font-weight: 500;
  color: #000;
}

.riderNameArrivingTime p:nth-child(2) {
  font-size: 1.4rem;
  font-weight: 500;
  color: #848484;
}

.pickupDropPoint {
  font-size: 1.2rem;
  color: #000;
  margin-bottom: 1rem;
}

.pickupDropPoint p {
  display: flex;
  align-items: center;
}

.dot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;

  background-color: #000;
  margin-right: 1rem;
}

.green {
  background-color: #69a43c;
}

.red {
  background-color: #ed7061;
}

.imageContent {
  position: relative;
}

.userGeneric {
  position: absolute;
  height: 87px;
  width: 87px;

  left: -91px;
  top: -1px;
  background-color: #e9e9e9;
  padding: 0.2rem;
  border-radius: 50%;
}

.hrDivider {
  border: none;
  background-color: #dedede;
  height: 1px;
}

.lowerPart {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 1rem;

  padding: 1rem;
}

.lowerPart button {
  background-color: transparent;
  border: none;
  outline: none;

  font-size: 1.4rem;
  font-weight: 600;
}

.lowerPart hr {
  transform: rotate(90deg);

  width: 10px;
  height: 1px;
  background-color: #000;
  border: none;
}
