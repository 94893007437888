.loadingBids {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 4rem;
  padding-top: 3.4rem;
}

.para {
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
}

.hourGlass {
  height: 61px;
  width: 46px;
}
