.numberPlateCarNameRatingCard {
  width: 94%;

  background-color: #e9e9e9;
  border-radius: 3px;
}

.upperPart {
  padding-top: 0.4rem;
  display: flex;
  /* align-self: stretch; */
  justify-content: space-between;
}

.writtenContent {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1rem 0 1rem 1rem;
}

.numberPlateCarName p:nth-child(1) {
  font-size: 2.4rem;
  font-weight: 700;
  color: #000;
}

.numberPlateCarName p:nth-child(2) {
  font-size: 1.4rem;
  color: #000;

  margin-top: 0.4rem;
}

.nameRatingCarDetails {
  font-size: 1.4rem;
  font-weight: 700;
  /* color: #848484; */
  color: #387bf5;
}

.nameRatingCarDetails p span {
  color: #848484;
  margin-left: 0.6rem;
}

.imageContent {
  position: relative;
}

.userGeneric {
  position: absolute;
  height: 58px;
  width: 58px;

  left: -157px;
  top: 25px;
  background-color: #e9e9e9;
  padding: 0.2rem;
  border-radius: 50%;
}

.cab {
  /* position: absolute;
  width: 138px;
  height: auto;
  left: -138px;
  top: 30px; */

  position: absolute;
  width: 138px;
  height: 80px;
  left: -138px;
  object-fit: cover;
  top: 16px;
  padding-right: 5px;
}

.hrDivider {
  border: none;
  background-color: #dedede;
  height: 1px;
}

.lowerPart {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 1rem;
  width: 100%;
  padding: 1rem;
}

.lowerPart button {
  background-color: transparent;
  border: none;
  outline: none;

  font-size: 1.4rem;
  font-weight: 600;
}

.lowerPart hr {
  transform: rotate(90deg);

  width: 10px;
  height: 1px;
  background-color: #000;
  border: none;
}

.flashingText {
  animation: flash 2s infinite;
}
@keyframes flash {
  0% {
    color: #347c00;
  }
  30% {
    color: rgb(167, 22, 22);
  }

  60% {
    color: #85d948;
  }
  100% {
    color: rgb(239, 59, 59);
  }
}
