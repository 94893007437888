.postRideTo {
  display: flex;
  flex-direction: column;

  /* align-items: center; */
  width: min(425px, 100vw);

  margin-top: 1rem;
  gap: 2rem;
  height: 100%;
  overflow-y: clip;
}

.list {
  height: calc(100vh - 400px);
  overflow-y: auto;
}

.autoComplete {
  border: 2px solid black;
}

.googleMapComponent {
  width: 94%;
  aspect-ratio: 1/1;
  background-color: #383838;

  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;
}

.bottomAd {
  width: 100%;
  height: 100px;
  background-color: rgb(253, 242, 233);
  position: absolute;
  bottom: 0;
}
