.hamburgerSideClosing {
  position: absolute;
  /* top: 0; */
  right: 0;
  width: 30%;
  height: 100vh;
  z-index: 10;
  background-color: transparent;
}

.liClass img {
  height: 24px;
  width: 24px;
}
.liClassImg {
  filter: invert();
}

.liClassWa {
  filter: none;
}
