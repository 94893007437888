.arrowBackWhite {
  margin-top: auto;
  margin-bottom: 2rem;
  /* margin-left: 1rem; */
  align-self: flex-start;
  padding-left: 2rem;
  padding-bottom: 1rem;
  height: 32px;
  /* width: 29px; */
  justify-self: end;
}
