.otpAndParagraph {
  font-size: 1.6rem;
  text-align: center;
  /* margin-top: 1rem; */
}
.otpPickupbutton {
  display: flex;
  justify-content: space-between;
}

.otpOnly {
  margin: 1rem 0;
  font-size: 2rem;

  color: orange;
  font-weight: bold;
}

.paragraphOnly {
}
