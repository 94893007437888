.googleMapComponentPolyLineOriginDestination {
  width: 94%;
  /* aspect-ratio: 5/5; */
  /* Caluclated for the polyline map to occupy the extra space */
  height: 45vh;
  background-color: transparent;

  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;
}
