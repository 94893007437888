.campaignDialogBox {
  position: absolute;
  z-index: 10;

  height: 90vh;
  width: min(100vw, 425px);
}

.modalBackdrop {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1px);

  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #fc7f1860; */
}
.visibleModal {
  background-color: #353535;
  background-image: linear-gradient(135deg, #252525 0%, rgb(52, 52, 52) 100%);
  width: max(300px, 85%);
  padding: 0.6rem;
  border-radius: 5px;

  /* border: 2px solid rgba(255, 166, 0, 0.093); */

  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.modalMainHeading {
  margin-top: 1rem;
  font-size: 1.8rem;
  font-weight: 400;
  text-align: center;
  color: #f6f2ff;
}

.modalSubHeading {
  font-size: 1.4rem;
  color: rgb(169, 169, 169);
  text-align: center;
  /* margin-bottom: 1rem; */
}

.paraDiv {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.paraDiv2 {
  display: flex;
  flex-direction: column;
  /* gap: -0.5rem; */
}
.emailDiv {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.para {
  /* margin-top: -1rem; */
  text-align: center;
  font-size: 1.6rem;
  /* margin-bottom: -1rem; */
  color: #f6f2ff;
}

.para2 {
  margin-top: 1rem;
  text-align: center;
  font-size: 1.6rem;
  /* margin-bottom: -1rem; */
  color: #d0d0d0;
  width: 75%;
  align-self: center;
  letter-spacing: 1px;
  line-height: 20px;
}

.para3 {
  margin-top: 1rem;
  text-align: center;
  font-size: 1.6rem;
  /* margin-bottom: -1rem; */
  color: #d0d0d0;
  /* width: %; */
  align-self: center;
  letter-spacing: 1px;
  line-height: 10px;
}

.link {
  text-decoration: none;
  color: #dadada;
}

.email {
  font-size: 1.6rem;
  /* margin-top: 1rem; */
  text-align: center;
  color: #aeaeae;
  /* line-height: 10px; */
  text-decoration: none;
}

.buttonRow {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 2rem;
}

.button {
  /* Resetting default styles */
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  /* line-height: normal; */
  /* text-align: inherit; */
  /* text-decoration: none; */
  cursor: pointer;
  /* overflow: visible; */
  outline: none;

  font-size: 1.6rem;
  padding: 0.6rem 1rem;
  /* background-color: #fc8018; */
  border-radius: 5px;

  box-shadow: inset 0 0 0 1px #ff7300;

  min-width: 96px;
}

.ulList {
  font-size: 1.4rem;
  color: rgb(169, 169, 169);
  list-style: none;

  padding-left: 1rem;
}
