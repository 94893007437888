.makePaymentCcAvenue {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
}

.nominalFeeButton {
  display: flex;

  /* align-items: center; */
  /* justify-content: space-between; */
  width: 94%;
  background-color: #dcdcdc;

  border-radius: 3px;
}
.infoBar {
  display: flex;
  width: 150%;

  flex-direction: column;

  color: #000;
  align-items: flex-start;
  justify-content: center;

  padding-left: 1rem;
}
.infoBar span:nth-child(1) {
  font-size: 1.6rem;
  font-weight: 700;
}

.infoBar span:nth-child(2) {
  font-size: 1.4rem;
  font-weight: 500;
  color: #848484;
}

.riderNamePickupDropCancelCard {
  width: 94%;

  background-color: #e9e9e9;
  border-radius: 3px;
}

.upperPart {
  padding-top: 0.4rem;
  /* align-self: stretch; */
}

.writtenContent {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin: 1rem 0 0 1rem;
}

.riderNameArrivingTime p:nth-child(1) {
  font-size: 2rem;
  font-weight: 500;
  color: #000;
}

.pickupDropPoint {
  font-size: 1.6rem;
  color: #000;
  /* margin-bottom: 1rem; */
}

.pickupDropPoint p {
  display: flex;
  align-items: center;
}

.pickup {
  display: flex;
  align-items: center;
}

.drop {
  display: flex;
  align-items: center;
}

.dot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;

  background-color: #000;
  margin-right: 1rem;
  aspect-ratio: 1/1;
}

.green {
  background-color: #69a43c;
}

.red {
  background-color: #ed7061;
}
.selectBidContinue {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* height: 100%; */
}

.bidHeadingAndSelect {
  width: 94%;
  /* align-self: stretch; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem;
}

.heading {
  font-size: 1.8rem;
  font-weight: 700;
  color: #fc8018;
  text-align: center;
  width: 100%;
}

.heading span {
  font-weight: 400;
}

.bidCardDiv {
  width: 100%;
  height: calc(100vh - 450px);
  /* aspect-ratio: 1/1; */
  /* flex-grow: 1; */
  /* set the flex-grow property to 1 */
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  /* margin-bottom: 1rem; */
}
/* 
.selectBidContinue:nth-child(3) {
  margin-top: auto;
} */

.bottomButton {
  align-self: stretch;
  margin-top: 1rem;
}

/* .selectDiv {
  margin: 0.2rem;
} */

.sortDivBase {
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-align: center; */

  gap: 1px;

  background-color: #ffffff;

  padding: 1px;

  border-radius: 30px;
  font-size: 1.6rem;

  /* font-size: 1.6rem; */
}
.filterButton {
  /* Resetting default styles */
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  /* line-height: normal; */
  /* text-align: inherit; */
  /* text-decoration: none; */
  cursor: pointer;
  /* overflow: visible; */
  /* outline: none; */

  padding: 0.4rem;
  background-color: #000;

  transition: background-color 0.3s ease-out;
}

.selectedFilter {
  background-color: #fc8018;
}

.leftButton {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding: 0.4rem 1rem;
  margin: 0;
}

.rightButton {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 0.4rem 1rem;
}

.sortText {
  font-size: 1.4rem;
  font-weight: 500;
}

.arrowSortUpDown {
  height: 11px;

  width: 15px;
}
.homeButton {
  border: none;
  background: none;
  margin: 0;
  font: inherit;
  cursor: pointer;
  padding: 1.6rem; /* Adjust the padding value according to your needs */
  outline: none;
  border-radius: 30px; /* Adjust the borderRadius value according to your needs */
  min-width: 130px;
  background-color: #419c00;
  color: #fff;
  font-weight: bold;
  /* -webkit-tap-highlight-color: transparent; */
  /* transition: background-color 0.3s ease-in; */
  user-select: none;
}
