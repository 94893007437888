.postRideTo {
  display: flex;
  flex-direction: column;

  /* align-items: center; */
  width: min(425px, 100vw);

  margin-top: 1rem;
  gap: 2rem;
  height: 100%;
  overflow-y: clip;
}

.list {
  height: calc(100vh - 400px);
  overflow-y: auto;
}

.autoComplete {
  border: 2px solid black;
}

.googleMapComponent {
  width: 94%;
  aspect-ratio: 1/1;
  background-color: #383838;

  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;
}

.bottomAd {
  width: 100%;
  height: 100px;
  background-color: rgb(253, 242, 233);
  position: absolute;
  bottom: 0;
}
.bottomAdImage {
  width: 50%;
  object-fit: "contain";
}
.bottomAdImageCustom {
  margin-left: 4rem;
}

.bookRideWith {
  height: 73px;
  width: 94%;

  background-color: #383838;

  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0.5rem;
  gap: 2rem;
  margin: 0 auto;
}

.backButton {
  padding: 1rem;
  background-color: #4b4b4b;
  border-radius: 50%;
}

.cabImage {
  height: 42px;
}

.bookRideText {
  font-size: 1.8rem;

  font-weight: 700;
}

.bookRideTextGreen {
  color: #419c00;
}

.optionStyle::selection {
  background-color: #383838;
}
.optionStyle:hover {
  background-color: #383838;
}

.logsBottomModal {
  position: absolute;
  z-index: 10;
  top: 75px;
  width: min(100vw, 425px);

  display: flex;
  align-items: center;
  justify-content: center;
}

.visibleModal {
  background-color: #393939;
  width: max(300px, 75%);
  padding: 1rem;
  border-radius: 5px;

  /* border: 2px solid rgba(255, 166, 0, 0.093); */

  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.InputlogsBottomModal {
  position: absolute;
  z-index: 10;

  height: 90vh;
  width: min(100vw, 425px);
}

.InputModalBackdrop {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1px);

  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #fc7f1860; */
}
.InputVisibleModal {
  background-color: #393939;
  width: max(300px, 75%);
  padding: 1rem;
  border-radius: 5px;

  /* border: 2px solid rgba(255, 166, 0, 0.093); */

  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.modalMainHeading {
  margin-top: 1rem;
  font-size: 1.8rem;
  font-weight: 400;
  text-align: center;
}
