.bookOnWhatsAppLine {
  display: flex;
  /* gap: 1rem; */
  /* font-size: 1.4rem; */
}

.bookOnWhatsApp {
  font-size: 1.8rem;
  color: #313131;
  font-weight: bold;
  background-color: #43d836;

  padding: 0.6rem 1.2rem 0.32rem 1.2rem;

  border-radius: 25px;

  /* display: flex; */
  /* gap: 1rem; */
}
