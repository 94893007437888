.notFound {
  display: flex;
  flex-direction: column;
  /* margin-top: 2rem; */
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 80vh;

  /* width: 94%; */
}
