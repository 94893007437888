.scrollable {
  height: 90vh;
  overflow-y: auto;
  padding-right: 10px;
  width: 100%;
  padding: 0 2rem;

  /* scrollbar-width: thin;
    scrollbar-color: #555555 #00000000; */
}

.refundPolicy {
  display: flex;
  flex-direction: column;
  /* margin-top: 2rem; */
  gap: 1rem;
  align-items: flex-start;
  justify-content: center;
  /* height: 95vh; */

  /* width: 94%; */
  /* padding-top: 6rem; */
  /* overflow-y: auto; */
}

.refundPolicy h1 {
  font-size: 2rem;
  margin-left: -2rem;
}

.refundPolicy h2 {
  font-size: 1.8rem;
  margin-left: -2rem;
}

.refundPolicy p {
  font-size: 1.6rem;
  text-align: justify;
}

.refundPolicy ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.refundPolicy li {
  font-size: 1.4rem;
}
