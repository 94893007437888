/* Default styles have not been removed for ease of development */
/* For overriding any default styles override them with  */
/* Cleaning of this file will be done after the routes are done */

/* CSS variables. */
:root {
  /* --PhoneInput-color--focus: none; */
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  /* --PhoneInputCountrySelect-marginRight: 0; */
  --PhoneInputCountrySelectArrow-width: 0rem;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 0;
  --PhoneInputCountrySelectArrow-opacity: 1;
  --PhoneInputCountrySelectArrow-color: #000;
  /* --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus); */
  /* --PhoneInputCountrySelectArrow-transform: rotate(45deg); */
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 2em;
  --PhoneInputCountryFlag-borderWidth: 0;
  --PhoneInputCountryFlag-borderColor: #e9e9e9;
  /* --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus); */
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInput {
  /* This is done to stretch the contents of this component. */
  display: flex;
  align-items: center;

  gap: 1rem;
  justify-content: center;
  width: 94%;
}

.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
	   to make room for the extension input */
  min-width: 0;

  height: 49px;
  background-color: #e9e9e9;
  border-radius: 3px;
  font-size: 1.6rem;
  padding: 1rem;
  font-weight: 600;

  outline: none;
  box-shadow: 0;
  margin: 0;
  border: none;
}

.PhoneInputCountryIcon {
  width: calc(
    var(--PhoneInputCountryFlag-height) *
      var(--PhoneInputCountryFlag-aspectRatio)
  );
  height: var(--PhoneInputCountryFlag-height);

  margin: 0 1rem;
  width: 34px;
  height: 23px;
  background-color: transparent;
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
  /* background-color: var(--PhoneInputCountryFlag-backgroundColor--loading); */
  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
  /* box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor); */

  box-shadow: none;
  background-color: transparent;
}

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */
  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  /* display: block; */
  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  /* margin-right: var(--PhoneInputCountrySelect-marginRight); */

  background-color: #e9e9e9;
  height: 49px;
  width: 92px;
  border-radius: 3px;
  /* align-self: unset; */
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 50%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: unset;
  content: "";
  width: unset;
  height: unset;
  margin-left: unset;
  border-style: unset;
  border-color: unset;
  border-top-width: unset;
  border-bottom-width: unset;
  border-left-width: unset;
  border-right-width: unset;
  border: none;
  transform: unset;
  opacity: unset;

  background-image: url("../../../assets/icons/arrowFillDown.svg");

  color: #000;
  height: 7px;
  width: 13px;
  margin-left: 1rem;
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
  color: none;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus);

  box-shadow: none;
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
  color: none;
}
