.bidCard {
  width: 94%;
  display: flex;
  /* align-self: stretch; */
  justify-content: space-between;
  background-color: #e9e9e9;
  border-radius: 3px;

  margin: 0 auto;
}

.bidCard.selected {
  box-shadow: inset 0 0 0 3px #fc8018;
}

.writtenContent {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1rem 0 1rem 1rem;
}

.priceAway p:nth-child(1) {
  font-size: 2.4rem;
  font-weight: 700;
  color: #000;
}

.priceAway p:nth-child(2) {
  font-size: 1.4rem;
  color: #848484;
}

.priceText {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.vehicleTypeSpanStyle {
  font-size: 1.6rem;
  color: #42a90b;
  /* font-weight: 400; */
}

.nameRatingCarDetails {
  font-size: 1.4rem;
  font-weight: 500;
  color: #848484;
}

.imageContent {
  position: relative;
}

.userGeneric {
  position: absolute;
  height: 58px;
  width: 58px;

  left: -157px;
  top: 25px;
  background-color: #e9e9e9;
  padding: 0.2rem;
  border-radius: 50%;
}

.cab {
  position: absolute;
  width: 138px;
  height: 80px;
  left: -138px;
  object-fit: cover;
  top: 16px;
  padding-right: 5px;
}
